import { Action } from '@ngrx/store';
import { BannerMessageListSetting } from '../../models/config-settings';

export enum ConfigBannerMessageListSettingsActionTypes {
  ConfigBannerMessageList = '[ConfigureBMListSettings] ConfigBannerMessageList',
}

export class SetBannerMessageListSetting implements Action {
  readonly type = ConfigBannerMessageListSettingsActionTypes.ConfigBannerMessageList;
  constructor(public payload: BannerMessageListSetting) {}
}

export type ConfigBannerMessageListSettingsActions = SetBannerMessageListSetting;
