import moment from 'moment';
import {NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

export function isValidDate(raw: any): boolean {
  return moment(new Date(raw)).isValid();
}

export function convertToNgbDateStruct(dateTime: Date): NgbDateStruct | null {
  if (dateTime.getFullYear) {
    return {year: dateTime.getFullYear(), month: dateTime.getMonth() + 1, day: dateTime.getDate()} as NgbDateStruct;
  } else {
    return null;
  }
}

export function convertToNgbTimeStruct(dateTime: Date): NgbTimeStruct | null {
  if (dateTime.getHours) {
    return {hour: dateTime.getHours(), minute: dateTime.getMinutes(), second: dateTime.getSeconds()} as NgbTimeStruct;
  } else {
    return null;
  }
}

export function convertHourMinuteToNgbTimeStruct(hour: any, minute: any) {
  if (!isNaN(hour) && !isNaN(minute)) {
    hour = parseInt(hour, 10);
    minute = parseInt(minute, 10);
    if (hour >= 0 && hour < 24 && minute >= 0 && minute < 60) {
      return {hour, minute, second: 0} as NgbTimeStruct;
    }
  }
  // console.log('Failed to convert hh:mm into NgbTimeStruct. hour=', hour, 'minute=', minute);
  return null;
}

export function convertToDate(date: NgbDateStruct = null, time: NgbTimeStruct = null): Date | null {
  if (!date || !time) {
    return null;
  }
  const dateTime = new Date(date.year, date.month - 1, date.day, time.hour, time.minute, time.second);
  // console.log('convertToDate: Returning date:', dateTime);
  return dateTime;
}

