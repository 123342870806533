<ng-container [formGroup]="form">
  <div class="detail card shadow p-3" [formArrayName]="urlsArrayName">
    <ng-container [formGroupName]="index">
      <div class="form-group">
        <textarea class="form-control"
                  formControlName="url"
                  [id]="appendId()+'url-'+index"
                  rows="5"
                  trim="blur">
        </textarea>
        <div class="text-danger">
          <span *ngIf="showDupUrlError(index)" [id]="appendId()+'url-duplicate-'+index">Urls must be unique</span>
          <span *ngIf="hasRequiredError(index) && isSubmitted" [id]="appendId()+'url-required-'+index">Url is required.</span>
          <span *ngIf="hasMoreAsterisksError(index) && isSubmitted" [id]="appendId()+'url-asterisk-number-'+index">If a url has a '*' it must only have one.</span>
          <span *ngIf="hasAsterisksError(index) && isSubmitted" [id]="appendId()+'url-asterisk-zero-'+index">exclude url cannot have '*'.</span>
          <span *ngIf="hasUrlIsInDomainError(index) && isSubmitted" [id]="appendId()+'url-asterisk-domian-'+index">* can not be in the url domain</span>
        </div>
      </div>
      <div>
        <button type="button" class="btn btn-outline-danger float-right" [id]="appendId()+'remove-btn-'+index" (click)="onRemove()" [disabled]="!allowRemoval">Remove</button>
      </div>
    </ng-container> 
  </div>
</ng-container>
