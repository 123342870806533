import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {ConfigService} from '../shared/config.service';
import {OauthService} from '../shared/oauth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  AdminRole = 'banner_admin';
  Aud: string;

  constructor(private configService: ConfigService, private oauthService: OauthService, private router: Router) {}
  /**
   * This method examines whether or not a user has the banner admin role and doesn't allow them to activate routes protected by this guard.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.Aud) {
      const authConf = this.configService.settings.oauthConfig
        .find(c => c.name.toLocaleLowerCase() === 'Azure-Banner-API'.toLocaleLowerCase());

      this.Aud = (authConf.extraQueryParams as any).resource;
    }
    const token = this.oauthService.tokenInfos
      .find(t => t.accessTokenClaims.aud.toLocaleLowerCase() === this.Aud.toLocaleLowerCase());
    const isAuthorized = token
      && token.accessTokenClaims
      && token.accessTokenClaims.roles
      && token.accessTokenClaims.roles.includes(this.AdminRole);

    if (!isAuthorized) {
      this.router.navigate(['/unauthorized']);
    }
    return isAuthorized;
  }
}
