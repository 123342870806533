import { UntypedFormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { constructor } from 'testdouble';

export const asteriskNumberValidator: ValidatorFn = ((formControl: UntypedFormControl): ValidationErrors | null => {
    const urlToArray = formControl.value.split('');
    const count = urlToArray.reduce((n, val) =>  n + (val === '*'), 0);
    if (count > 1) {
        return {multipleAsterisksError: count} ;
    }
    return null;
});

export const asteriskCountZeroValidator: ValidatorFn = ((formControl: UntypedFormControl): ValidationErrors | null => {
    const urlToArray = formControl.value.split('');
    const count = urlToArray.reduce((n, val) =>  n + (val === '*'), 0);
    if (count >= 1) {
        return {asterisksError: count} ;
    }
    return null;
});

export const urlIsInDomainValidator: ValidatorFn = ((formControl: UntypedFormControl): ValidationErrors | null => {
    let url = formControl.value;
    const httpIndex = url.indexOf('://');
    let slashIndex = url.indexOf('/');
    const error = { AsteriskInDomianError: 'Url is Invalid.' };

    if (url === undefined || url === '') {
        return null;
    }
    if ( httpIndex > 0 ) {
        url = url.slice(httpIndex + 3);
        slashIndex = url.indexOf('/');
    }
    if ( url.indexOf('www.') >= 0 ) {
        url = url.slice(4);
        slashIndex = url.indexOf('/');
    }
    if (slashIndex === -1) {
        url = url + '/';
        slashIndex = url.indexOf('/');
    }

    if (url === undefined || url === '') {
        return error;
    }
    url = url.slice(0, slashIndex);

    let isValid = true;
    const spl = url.split('.').reverse();
    isValid = (spl[0].indexOf('*') === -1);

    if (isValid && spl.length >= 2) {
        // if only 2 then * should not be there in the first and last part of URL Authority.
        isValid = (spl[1].indexOf('*') === -1);
    }

    if (!isValid) {
        // URl found as invalid.
        return error;
    }
    return null;
});
