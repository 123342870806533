import { Action } from '@ngrx/store';
import {BannerMessageModel, PagingAndSorting} from '../../models/config-settings';

export enum BannerMessageActionTypes {

  CreateBannerMessage = '[BannerMessage API] Create BannerMessage',
  CreateBannerMessageSuccess = '[BannerMessage API] Create BannerMessage Success',
  CreateBannerMessageError = '[BannerMessage API] Create BannerMessage Error',

  GetBannerMessages = '[BannerMessage API] Get BannerMessages',
  GetBannerMessagesSuccess = '[BannerMessage API] Get BannerMessages Success',
  GetBannerMessagesError = '[BannerMessage API] Get BannerMessages Error',

  UpdateBannerMessage = '[BannerMessage API] Update BannerMessage',
  UpdateBannerMessageSuccess = '[BannerMessage API] Update BannerMessage Success',
  UpdateBannerMessageError = '[BannerMessage API] Update BannerMessage Error',

 DeleteBannerMessage = '[BannerMessage API] Delete BannerMessage',
  DeleteBannerMessageSuccess = '[BannerMessage API] Delete BannerMessage Success',
  DeleteBannerMessageError = '[BannerMessage API] Delete BannerMessage Error',
}

export class CreateBannerMessage implements Action {
  readonly type = BannerMessageActionTypes.CreateBannerMessage;
  constructor(public payload: BannerMessageModel) {}
}

export class CreateBannerMessageSuccess implements Action {
  readonly type = BannerMessageActionTypes.CreateBannerMessageSuccess;
  constructor(public payload: BannerMessageModel) {}
}

export class CreateBannerMessageError implements Action {
  readonly type = BannerMessageActionTypes.CreateBannerMessageError;
  constructor(public payload: any) {}
}

export class GetBannerMessages implements Action {
  readonly type = BannerMessageActionTypes.GetBannerMessages;
  // TODO: update payload min the future
  constructor(public payload?: PagingAndSorting) {}
}

export class GetBannerMessagesSuccess implements Action {
  readonly type = BannerMessageActionTypes.GetBannerMessagesSuccess;
  constructor(public payload: BannerMessageModel[]) {}
}

export class GetBannerMessagesError implements Action {
  readonly type = BannerMessageActionTypes.GetBannerMessagesError;
  constructor(public payload: any) {}
}


export class UpdateBannerMessage implements  Action {
  readonly  type = BannerMessageActionTypes.UpdateBannerMessage;
  constructor( public  payload: BannerMessageModel) {}
}

export class UpdateBannerMessageSuccess implements  Action {
  readonly  type = BannerMessageActionTypes.UpdateBannerMessageSuccess;
  constructor( public  payload: BannerMessageModel) {}
}

export class UpdateBannerMessageError implements  Action {
  readonly  type = BannerMessageActionTypes.UpdateBannerMessageError;
  constructor( public payload: any) {}
}

export class DeleteBannerMessage implements  Action {
  readonly  type = BannerMessageActionTypes.DeleteBannerMessage;
  constructor( public  payload: BannerMessageModel) {}
}

export class DeleteBannerMessageSuccess implements  Action {
  readonly  type = BannerMessageActionTypes.DeleteBannerMessageSuccess;
  constructor( public  payload: BannerMessageModel) {}
}

export class DeleteBannerMessageError implements  Action {
  readonly  type = BannerMessageActionTypes.DeleteBannerMessageError;
  constructor( public payload: any) {}
}

export type BannerMessageActions = CreateBannerMessage |
  CreateBannerMessageSuccess |
  CreateBannerMessageError |
  GetBannerMessages |
  GetBannerMessagesSuccess |
  GetBannerMessagesError |
  UpdateBannerMessage |
  UpdateBannerMessageError |
  UpdateBannerMessageSuccess|
  DeleteBannerMessage |
  DeleteBannerMessageError |
  DeleteBannerMessageSuccess;
