import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router, NavigationEnd, PRIMARY_OUTLET} from '@angular/router';
import {filter} from 'rxjs/operators';

interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
}

@Component({
  selector: 'banner-breadcrumb-nav',
  templateUrl: './breadcrumb-nav.component.html',
  styleUrls: ['./breadcrumb-nav.component.scss']
})
export class BreadcrumbNavComponent implements OnInit {

  private static ROUTE_DATA_BREADCRUMB = 'breadcrumb';

  public breadcrumbs: IBreadcrumb[];

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.breadcrumbs = [];
  }

  ngOnInit(): void {
    // Subscribe to NavigationEnd event
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      // Initialize breadcrumb array
      const root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
    });
  }

  getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    // Get the child routes
    const children: ActivatedRoute[] = route.children;

    // Return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      // Verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      // Get the route's URL segment
      const routeUrl = child.snapshot.url.map(segment => segment.path).join('/');
      // Append routeUrl to URL
      url += `/${routeUrl}`;

      // Verify the custom data property breadcrumb is specified on the route and that the label doesn't match the parent's label.
      if (
        child.snapshot.data &&
        child.snapshot.data.hasOwnProperty(BreadcrumbNavComponent.ROUTE_DATA_BREADCRUMB) &&
        child.snapshot.data[BreadcrumbNavComponent.ROUTE_DATA_BREADCRUMB] !== null &&
        child.snapshot.data[BreadcrumbNavComponent.ROUTE_DATA_BREADCRUMB] !==
        route.snapshot.data[BreadcrumbNavComponent.ROUTE_DATA_BREADCRUMB]
      ) {
        // Add breadcrumb
        const breadcrumb: IBreadcrumb = {
          label: child.snapshot.data[BreadcrumbNavComponent.ROUTE_DATA_BREADCRUMB],
          params: child.snapshot.params,
          url
        };
        breadcrumbs.push(breadcrumb);
      }
      // Recursion
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
