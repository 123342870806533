import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'addLeadingZeros',
  pure: false
})
export class LeadingZerosPipe implements PipeTransform {

  /**
   * Takes an input string and adds leading zeros to it to meet the target length. Used for the Time pickers.
   * @param input The input string to be zero-padded. This must be a whole number or it will return the original string.
   * @param targetLength The desired length of the output string.
   */
  transform(input?: any, targetLength: number = 2): string {
    // Convert the input to string for ease of handling
    const formattedInput = input + '';
    // If the user entered anything but numbers, then return the original string
    if (formattedInput === '' || formattedInput !== formattedInput.replace(/[^0-9]+/g, '')) {
      return formattedInput;
    }
    if (targetLength > formattedInput.length) {
      return '0'.repeat(targetLength - formattedInput.length) + input;
    } else if (formattedInput.length > targetLength) {
      return formattedInput.substr(-targetLength);
    }
    return formattedInput;
  }

}
