import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'banner-header',
  templateUrl: './bs-header.component.html',
  styleUrls: ['./bs-header.component.scss']
})

export class BsHeaderComponent implements OnInit {

  userProfile$: any;
  token: any;

  constructor(
    private domSanitizer: DomSanitizer,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userProfile$ = this.userService.getUserProfileFromAzure();
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
