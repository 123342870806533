<div class="modal-header">
  <h4 class="modal-title">Confirm</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(getCloseAction())">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

<div class="row">
  <div class="col-md-11 pl-3 pb-3"><span class="text">Are you sure you want to cancel and lose unsaved changes?</span><br/></div>
</div>

</div>
<div class="modal-footer">
    <button type="button" ngbAutofocus class="btn btn-outline-secondary" (click)="modal.close(getNoAction())" id="no">{{cancelActionText}}</button>
    <button type="button" class="btn btn-danger" (click)="modal.close(getYesAction())" id="yes">{{confirmActionText}}</button>
</div>
