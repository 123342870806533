import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {OauthService} from './oauth.service';
import {ConfigService} from './config.service';
import {switchMap} from 'rxjs/operators';
import {initOne} from 'mayo-js-oidc';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private oauthService: OauthService,
    private configService: ConfigService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url === 'conf.json') {
      return next.handle(request);
    }
    const setting = this.configService.settings;
    const map = setting.urlMappings.find(urlMapping => request.url.indexOf(urlMapping.url) >= 0);
    if (map) {
      const oauthConfig = setting.oauthConfig.find(config => config.name === map.oauthConfigName);
      return from(initOne(oauthConfig)).pipe(switchMap(tokenInfo => {
        console.log('Adding access token ' + tokenInfo.access_token);
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${tokenInfo.access_token}`
          }
        });
        return next.handle(request);
      }));
    }
  }
}
