import { BannerMessageModel } from '../../models/config-settings';
import {
  BannerMessageActions, BannerMessageActionTypes
} from '../../actions/banner-message/banner-message.actions';

export const featureName = 'BannerRule';

export interface State {
  bannerRule: BannerMessageModel;
  bannerMessages: BannerMessageModel[];
}

export const initialState: State = {
  bannerRule: {} as BannerMessageModel,
  bannerMessages: []
};

export function reducer(
  state = initialState,
  action: BannerMessageActions):
  State {
    switch (action.type) {
      case BannerMessageActionTypes.CreateBannerMessage:
        return {...state, bannerRule: action.payload};
      case BannerMessageActionTypes.CreateBannerMessageSuccess:
        return {...state, bannerRule: action.payload};
      case BannerMessageActionTypes.CreateBannerMessageError:
        return {...state, bannerRule: action.payload};
      case BannerMessageActionTypes.GetBannerMessagesSuccess:
        return {...state, bannerMessages: action.payload};
      default:
        return state;
    }
}
