import {Routes } from '@angular/router';
import {DocumentationComponent } from '../components/documentation/documentation.component';
import {UnAuthorizedComponent} from '../components/un-authorized/un-authorized.component';
import {AuthGuard} from './auth-guard.service';
import {BannerMessageListComponent} from '../components/banner-message-list/banner-message-list.component';
import {BannerMessageFormComponent} from '../components/banner-message-form/banner-message-form.component';

export const routes: Routes = [
  { path: 'messages',
    canActivate: [AuthGuard],
    data: {breadcrumb: 'Messages'},
    children: [
      { path: '',
        component: BannerMessageListComponent,
      },
      { path: 'create',
        component: BannerMessageFormComponent,
        data: {breadcrumb: 'Create'}
      },
      {
        path: 'update/:id',
        component: BannerMessageFormComponent,
        data: {breadcrumb: 'Edit'}
      }
     
    ]
  },
  { path: 'documentation',
    component: DocumentationComponent,
    data: {breadcrumb: 'Documentation'}
  },
  { path: 'unauthorized', component: UnAuthorizedComponent},
  { path: '', redirectTo: 'messages', pathMatch: 'full' },
  { path: '**', redirectTo: 'messages', pathMatch: 'full'}
];
