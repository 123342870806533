import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'banner-message-detail',
  templateUrl: './banner-message-detail.component.html',
  styleUrls: ['./banner-message-detail.component.scss']
})
export class BannerMessageDetailComponent implements OnInit {
  @Input() urlsArrayName;
  @Input() index;
  @Input() isSubmitted: boolean;
  @Input() form: UntypedFormGroup;
  @Input() allowRemoval: boolean;
  @Output() remove = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  /**
   * tell parent component to remove me.
   */
  onRemove(): void {
    this.remove.emit(this.index);
  }

  appendId(): string {
    return this.urlsArrayName === 'excludeUrls' ? 'ex-' : '';
  }

  showDupUrlError(index: number): boolean {
    if (!this.isSubmitted) {
      return false;
    }
    // check for nulls all the way down from the form group
    if (!(this.form && this.form.controls && this.form.controls[this.urlsArrayName] && this.form.controls[this.urlsArrayName].errors )) {
      return false;
    }

    const dupErrors = this.form.controls[this.urlsArrayName].errors.duplicateUrls;
    if (!dupErrors) {
      return false;
    }
    const dupForIndex = dupErrors.find(dup => dup.indexes.includes(index));
    return dupForIndex;
  }

  hasRequiredError(i: number) {
   if (!(this.form && this.form.controls && this.form.controls[this.urlsArrayName] &&
     (this.form.controls[this.urlsArrayName] as UntypedFormArray).controls
     && ((this.form.controls[this.urlsArrayName] as UntypedFormArray).controls[i] as UntypedFormGroup).controls.url.errors)) {
     return false;
   }
   return ((this.form.controls[this.urlsArrayName] as UntypedFormArray).controls[i] as UntypedFormGroup).controls.url.errors.required;
  }

  hasMoreAsterisksError(i: number) {
    if (this.urlsArrayName === 'excludeUrls') {
      return false;
    }
    if (!(this.form && this.form.controls && this.form.controls[this.urlsArrayName]
      && (this.form.controls[this.urlsArrayName] as UntypedFormArray).controls
      && ((this.form.controls[this.urlsArrayName] as UntypedFormArray).controls[i] as UntypedFormGroup).controls.url.errors)) {
      return false;
    }
    return ((this.form.controls[this.urlsArrayName] as UntypedFormArray).controls[i] as UntypedFormGroup).controls.url.errors.multipleAsterisksError;
  }
  hasAsterisksError(i: number) {
    if (!(this.form && this.form.controls && this.form.controls[this.urlsArrayName]
      && (this.form.controls[this.urlsArrayName] as UntypedFormArray).controls
      && ((this.form.controls[this.urlsArrayName] as UntypedFormArray).controls[i] as UntypedFormGroup).controls.url.errors)) {
      return false;
    }
    return ((this.form.controls[this.urlsArrayName] as UntypedFormArray).controls[i] as UntypedFormGroup).controls.url.errors.asterisksError;
  }

  hasUrlIsInDomainError(i: number) {
    if (this.urlsArrayName === 'excludeUrls') {
      return false;
    }
    if (!(this.form && this.form.controls && this.form.controls[this.urlsArrayName]
      && (this.form.controls[this.urlsArrayName] as UntypedFormArray).controls
      && ((this.form.controls[this.urlsArrayName] as UntypedFormArray).controls[i] as UntypedFormGroup).controls.url.errors)) {
      return false;
    }
    return ((this.form.controls[this.urlsArrayName] as UntypedFormArray).controls[i] as UntypedFormGroup).controls.url.errors.AsteriskInDomianError;
  }
}
