import { Pipe, PipeTransform } from '@angular/core';
import { BannerMessageType, BannerMessageModel } from 'src/app/models/config-settings';

@Pipe({
    name: 'bannerMessageTypePipe',
    pure: false
})

export class BannerMessageTypePipe implements PipeTransform {
    transform(bms: BannerMessageModel[], messageType: BannerMessageType): any[] {
        if (!bms) {
            return [];
        }
        if (messageType.alert === false && messageType.notice === false && messageType.warning === false) {
            return [];
        }
        if ( messageType.alert === true && messageType.notice === true && messageType.warning === true) {
            return bms;
        }
        if ( messageType.alert === true && messageType.notice === false && messageType.warning === false) {
            return bms.filter( b => b.type === 'Alert');
        }
        if ( messageType.alert === false && messageType.notice === true && messageType.warning === false) {
            return bms.filter( b => b.type === 'Notice');
        }
        if ( messageType.alert === false && messageType.notice === false && messageType.warning === true) {
            return bms.filter( b => b.type === 'Warning');
        }
        if ( (messageType.alert === true || messageType.notice === true) && messageType.warning === false) {
            return bms.filter( b => b.type === 'Alert' || b.type === 'Notice');
        }
        if ( (messageType.alert === true || messageType.warning === true) && messageType.notice === false) {
            return bms.filter( b => b.type === 'Alert' || b.type === 'Warning');
        }
        if ( (messageType.notice === true || messageType.warning === true) && messageType.alert === false) {
            return bms.filter( b => b.type === 'Notice' || b.type === 'Warning');
        }
    }
}
