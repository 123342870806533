import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js';
import '!svg-sprite-loader!node_modules/@mayoclinic/brand-logos/laboratories/mc-labs_black.svg';
import '!svg-sprite-loader!node_modules/@mayoclinic/brand-logos/laboratories/mc-labs_white.svg';
import '!svg-sprite-loader!node_modules/@mayoclinic/icons/design/search/search.svg';
import '!svg-sprite-loader!node_modules/@mayoclinic/icons/design/calendar/calendar-range.svg';
import '!svg-sprite-loader!node_modules/@mayoclinic/icons/design/check/check.svg';
import '!svg-sprite-loader!node_modules/@mayoclinic/icons/design/pencil/pencil.svg';
import '!svg-sprite-loader!node_modules/@mayoclinic/icons/design/trash/trash.svg';
import '!svg-sprite-loader!node_modules/@mayoclinic/icons/design/close/close.svg';
import '!svg-sprite-loader!node_modules/@mayoclinic/icons/design/arithmetic/plus.svg';
import '!svg-sprite-loader!node_modules/@fortawesome/fontawesome-free/svgs/regular/eye.svg';
import '!svg-sprite-loader!node_modules/@fortawesome/fontawesome-free/svgs/regular/eye-slash.svg';
import '!svg-sprite-loader!node_modules/@fortawesome/fontawesome-free/svgs/solid/info-circle.svg';
import '!svg-sprite-loader!node_modules/@fortawesome/fontawesome-free/svgs/solid/exclamation-triangle.svg';
import '!svg-sprite-loader!node_modules/@fortawesome/fontawesome-free/svgs/solid/exclamation-circle.svg';
import '!svg-sprite-loader!node_modules/@fortawesome/fontawesome-free/svgs/solid/pencil-alt.svg';
import '!svg-sprite-loader!node_modules/@fortawesome/fontawesome-free/svgs/solid/sort-down.svg';
import '!svg-sprite-loader!node_modules/@fortawesome/fontawesome-free/svgs/solid/sort-up.svg';


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
