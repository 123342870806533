import { Injectable } from '@angular/core';
import { withAdapter, fetchAdapter } from 'azure-user-profile';
import 'whatwg-fetch';

@Injectable({
  providedIn: 'root',
})

export class UserService {

  token: any;
  graphAPIkey = 'Azure-Graph-API';

  constructor() {
    const sessionStorageKeys = Object.keys(sessionStorage);
    const tokenKey = sessionStorageKeys.find(key => key.toLocaleLowerCase().includes(this.graphAPIkey.toLocaleLowerCase()));
    this.token = JSON.parse(sessionStorage.getItem(tokenKey));
  }

  async getUserProfileFromAzure() {
    if (!this.token || !this.token.access_token) {
      return null;
    }
    const { getUserPhoto, getUserProfile } = withAdapter(
      fetchAdapter(this.token.access_token)
    );
    return {
      photo: await getUserPhoto(),
      profile: await getUserProfile()
    };
  }

}
