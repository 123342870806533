import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from './config.service';
import {map, catchError} from 'rxjs/operators';
import {BannerMessageModel} from '../models/config-settings';

@Injectable({
  providedIn: 'root'
})

export class BannerMessageService {
  public baseUrl: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
    this.configService.loadConfig().then(() => this.baseUrl = this.configService.getSetting().apiBaseUrl);
  }

  createBannerMessage(bannerMessage: BannerMessageModel): Observable<any> {

    return this.http.post<any>(this.baseUrl + '/BannerMessages', bannerMessage)
      .pipe(
        map(createdBannerRule => {
          return createdBannerRule;
        })
      );
  }

  searchBannerMessages(): Observable<BannerMessageModel[]> {
    return this.http.post<any>(this.baseUrl + '/bannerMessages/AdvancedSearch', {})
    .pipe(
      map(bannerMessage => {
        return bannerMessage.data;
      })
    );
  }

  updateBannerMessage(bannerMessage: BannerMessageModel): Observable<BannerMessageModel> {
    return this.http.put<BannerMessageModel>(`${this.baseUrl}/BannerMessages`, bannerMessage);
  }

  deleteBannerMessage(bannerMessage: BannerMessageModel): Observable<BannerMessageModel> {
    return this.http.put<BannerMessageModel>(`${this.baseUrl}/BannerMessages/deleteBanner`, bannerMessage);
  }
}
