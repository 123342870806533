import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'banner-un-authorized',
  templateUrl: './un-authorized.component.html'
})
export class UnAuthorizedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
