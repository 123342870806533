import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State, featureName} from '../reducers/banner-reducer';

export const getBannerMessagesState = createFeatureSelector<State>(featureName);

export const getBannerMessage = createSelector(getBannerMessagesState,
    (state: State) => state.bannerMessages);

export const getBannerMessageByID = (id: string) => createSelector(getBannerMessagesState,
    (state: State) => state.bannerMessages.find( bm => bm.bannerMessageId === id));
