import { Pipe, PipeTransform } from '@angular/core';
import { BannerFilter, BannerMessageModel } from 'src/app/models/config-settings';
import * as R from 'ramda';


@Pipe({
    name: 'bannerMessageFilterPipe',
    pure: false
})

export class BannerMessageFilterPipe implements PipeTransform {
    transform(bms: BannerMessageModel[], messageType: BannerFilter): any[] {
        if (!bms || !messageType) {
            return [];
        }
        if (messageType.filterText.length === 0) {
            return bms;
        }
        const matches = bms.filter(bm => {
        const titleMatches =  messageType.title ? bm.title.toLocaleLowerCase().indexOf(messageType.filterText.toLocaleLowerCase()) > -1 : false;
        const contentMatches =  messageType.content ? bm.content.toLocaleLowerCase().indexOf(messageType.filterText.toLocaleLowerCase()) > -1 : false;
        const urlMatches = messageType.url ? bm.urls.some(url => url.toLocaleLowerCase().indexOf(messageType.filterText.toLocaleLowerCase()) > -1) : false;
        if (messageType.filterText) {
         return titleMatches || urlMatches || contentMatches;
        }
      });
        return matches;
    }
}


