import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
/**
 * This checks if a dumplicate url is contained in the form array.
 */
export const uniqueUrlValidator = (formArray: UntypedFormArray) => {
  const uniqueUrls = [];
  // loop through all the controls in the array and look at the url control's value.
  // build up an array with a mapping of url values and their index in the FormArray.
  formArray.controls.forEach( (group: UntypedFormGroup, index: number) => {
    const groupUrl = group.controls.url.value;
    const alreadyFound = uniqueUrls.some( dup => {
      if (dup && dup.url && groupUrl) {
        return dup.url.toLocaleLowerCase() === groupUrl.toLocaleString().toLocaleLowerCase();
      }
      return false;
    });
    // we don't already have the url stored in uniqueUrls so store an object in there
    if (!alreadyFound) {
      uniqueUrls.push({url: groupUrl, indexes: [index]});
    } else {
      // we already have this url stored in the uniqueUrls collection, so find the object in there and update the indexes
      // collection in there that stores the found indexes
      const uuIndex = uniqueUrls.findIndex(url => url.url.toLocaleLowerCase() === groupUrl.toLocaleString().toLocaleLowerCase());
      const uuObj = uniqueUrls[uuIndex];
      uuObj.indexes.push(index);
      uniqueUrls[uuIndex] = uuObj;
    }
  });

  // ok we're done looping now examine the array and return validation status;
  const dupUrls = uniqueUrls.filter(url => url.indexes.length > 1);
  return  (dupUrls && dupUrls.length > 0) ? { duplicateUrls: dupUrls } : null;
};

