import { ValidationErrors, ValidatorFn, UntypedFormGroup, FormControl } from '@angular/forms';
import {convertHourMinuteToNgbTimeStruct} from '../utils/date-utils';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * This function ensures that when end date is set, end time must also be set
 * @param group the form group containing end date and end time.
 */
export const endTimeRequiredWhenEndDateSet: ValidatorFn = (group: UntypedFormGroup): ValidationErrors | null => {
  const endDate = group.controls.endDate.value;
  const endTimeHour = group.controls.endTimeHour.value;
  const endTimeMinute = group.controls.endTimeMinute.value;
  let endTime = null;
  if ( !isNaN(endTimeHour) &&  !isNaN(endTimeMinute)) {
    endTime = convertHourMinuteToNgbTimeStruct(endTimeHour, endTimeMinute);
  }
  if (endDate && !endTime) {
    return { endTimeRequired: 'A valid end time is required when end date is provided' };
  }

  return null;
};

/**
 * This function ensures that when end time is set, end date must also be set
 * @param group the form group containing end date and end time.
 */
export const endDateRequiredWhenEndTimeSet: ValidatorFn = (group: UntypedFormGroup): ValidationErrors | null => {
  const endDate = group.controls.endDate.value;
  const endTimeHour = group.controls.endTimeHour.value;
  const endTimeMinute = group.controls.endTimeMinute.value;
  let endTime = null;
  if ( !isNaN(endTimeHour) &&  !isNaN(endTimeMinute)) {
    endTime = convertHourMinuteToNgbTimeStruct(endTimeHour, endTimeMinute);
  }
  if (endTime && !endDate) {
    return { endDateTimeRequired: 'End date is required when end time is provided' };
  }
  return null;
};

/**
 * This validator checks to see if the start date and time are in the past
 * @param group the form group containing the start date and start time
 */
export const startDateTimeNotInPast: ValidatorFn = (group: UntypedFormGroup): ValidationErrors | null => {
  const startDate = group.controls.startDate.value;
  const startTimeHour = group.controls.startTimeHour.value;
  const startTimeMinute = group.controls.startTimeMinute.value;
  let startTime = null;
  if ( !isNaN(startTimeHour) &&  !isNaN(startTimeMinute)) {
    startTime = convertHourMinuteToNgbTimeStruct(startTimeHour, startTimeMinute);
  }
  // Test

  // start date or start time hasn't been set yet.
  if (!startDate || !startTime) {
    return null;
  }

  const startDttm = new Date(startDate.year, (startDate.month - 1), startDate.day, startTime.hour, startTime.minute, startTime.second);
  const now = new Date();

  return startDttm < now ? { startDateTimeInPast: 'Start date and time can\'t be in the past' } : null;
};
export const endDateTimeAfterStartDateTime: ValidatorFn = (group: UntypedFormGroup): ValidationErrors | null => {

  const startDate = group.controls.startDate.value;
  const startTimeHour = group.controls.startTimeHour.value;
  const startTimeMinute = group.controls.startTimeMinute.value;
  let startTime = null;
  if ( !isNaN(startTimeHour) &&  !isNaN(startTimeMinute)) {
    startTime = convertHourMinuteToNgbTimeStruct(startTimeHour, startTimeMinute);
  }
  const endDate = group.controls.endDate.value;
  const endTimeHour = group.controls.endTimeHour.value;
  const endTimeMinute = group.controls.endTimeMinute.value;
  let endTime = null;
  if ( !isNaN(endTimeHour) &&  !isNaN(endTimeMinute)) {
    endTime = convertHourMinuteToNgbTimeStruct(endTimeHour, endTimeMinute);
  }

  if ((!startDate || !startTime) || (!endDate || !endTime)) {
    return null;
  }
  const startDt: Date = new Date(startDate.year, (startDate.month - 1), startDate.day, startTime.hour, startTime.minute, startTime.second);
  const endDt: Date = new Date(endDate.year, (endDate.month - 1), endDate.day, endTime.hour, endTime.minute, endTime.second);
  return startDt >= endDt ? { InvalidDateTimeRange: 'Start Date and Time can\'t be after the End Date and Time' } : null;
};
