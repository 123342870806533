import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule} from '@angular/core';
import { AppRoutingModule } from './router/app-routing.module';
import { AppComponent } from './app.component';
import { BsHeaderComponent } from './layout/bs-header/bs-header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { OauthService } from './shared/oauth.service';
import { HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { ConfigService } from './shared/config.service';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { UnAuthorizedComponent } from './components/un-authorized/un-authorized.component';
import { BannerMessageListComponent } from './components/banner-message-list/banner-message-list.component';
import {Action, StoreModule} from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './store/effects/app.effects';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalConfirmComponent } from './shared/ngbd-modal-confirm/ngbd-modal-confirm.component';
import { TokenInterceptor } from './shared/interceptor.services';
import * as bannerMessage from 'src/app/store/reducers/banner-reducer';
import { BreadcrumbNavComponent } from './shared/breadcrumb-nav/breadcrumb-nav.component';
import { RouterModule } from '@angular/router';
import { BannerMessageFormComponent } from './components/banner-message-form/banner-message-form.component';
import { BannerMessageDetailComponent } from './components/banner-message-detail/banner-message-detail.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { BannerMessageActivePipe } from './shared/pipe/banner-message-active.pipe';
import { BannerMessageTypePipe } from './shared/pipe/banner-message-type.pipe';
import { BannerMessageFilterPipe } from './shared/pipe/banner-message-filter.pipe';
import { BannerMessageSortPipe } from './shared/pipe/banner-message-sort.pipe';
import { OrderModule } from 'ngx-order-pipe';
import * as bannerMessageListSetting from 'src/app/store/reducers/banner-message-list.reducer';
import {LeadingZerosPipe} from './shared/pipe/leading-zeros.pipe';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
export const onAppStart = (configService: ConfigService, oAuthService: OauthService) => {
  return () => {
    if (!sessionStorage.getItem('deepLink')) {
      const urlPart = `${window.location.pathname}${window.location.search}`;
      sessionStorage.setItem('deepLink', urlPart);
    }
    return configService.loadConfig().then(() => oAuthService.getTokens());
  };
};

export function sessionStorageReducer(reducer: (state: any, action: Action) => any) {
  const storageKey = 'ngrx-store';
  const isUndefined = (value) => typeof value === 'undefined';
  return (state: any, action: Action) => {
    if (isUndefined(state)) {
      const persistedState = sessionStorage.getItem(storageKey);
      return persistedState ? JSON.parse(persistedState) : reducer(state, action);
    }
    const nextState = reducer(state, action);
    sessionStorage.setItem(storageKey, JSON.stringify(nextState));
    return nextState;
  };
}

@NgModule({
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        BsDatepickerModule.forRoot(),
        StoreModule.forRoot({}, { metaReducers: [sessionStorageReducer] }),
        StoreModule.forFeature(bannerMessage.featureName, bannerMessage.reducer),
        StoreModule.forFeature(bannerMessageListSetting.featureName, bannerMessageListSetting.reducer),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        EffectsModule.forRoot([AppEffects]),
        NgbModule,
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'danger', // set defaults here
        }),
        RouterModule,
        NgxTrimDirectiveModule,
        NgxPaginationModule,
        OrderModule
    ],
    declarations: [
        AppComponent,
        BsHeaderComponent,
        DocumentationComponent,
        UnAuthorizedComponent,
        BannerMessageListComponent,
        NgbdModalConfirmComponent,
        BreadcrumbNavComponent,
        BannerMessageFormComponent,
        BannerMessageDetailComponent,
        BannerMessageActivePipe,
        BannerMessageTypePipe,
        BannerMessageFilterPipe,
        BannerMessageSortPipe,
        LeadingZerosPipe
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: onAppStart,
            deps: [ConfigService, OauthService],
            multi: true
        },
        { provide: 'Window', useValue: window },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
