import {Component, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'banner-ngbd-modal-confirm',
  templateUrl: './ngbd-modal-confirm.component.html',
  styleUrls: ['./ngbd-modal-confirm.component.scss']
})
export class NgbdModalConfirmComponent {

  public static   YES_ACTION =  1;
  public static    NO_ACTION = -1;
  public static CLOSE_ACTION =  0;

  @Input() title: string;
  @Input() body: string;
  @Input() confirmActionText  = 'Yes';
  @Input() cancelActionText   = 'No';

  constructor(public modal: NgbActiveModal) { }

  getYesAction(): number    { return NgbdModalConfirmComponent.YES_ACTION; }
  getNoAction(): number     { return NgbdModalConfirmComponent.NO_ACTION; }
  getCloseAction(): number  { return NgbdModalConfirmComponent.CLOSE_ACTION; }

}
