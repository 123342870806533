import {Component, OnInit} from '@angular/core';
import {
  BannerMessageModel,
  PagingAndSorting,
  BannerMessageType,
  BannerFilter,
  BannerMessageSort,
  BannerMessageListSetting
} from '../../models/config-settings';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import * as state from '../../store/reducers/banner-reducer';
import { GetBannerMessages } from '../../actions/banner-message/banner-message.actions';
import { getBannerMessage } from '../../store/selector/banner-message.selector';
import { isValidDate } from '../../shared/utils/date-utils';
import { getBMListStickySettingsSelector } from '../../store/selector/bmList-setting.selector';
import { SetBannerMessageListSetting } from '../../actions/banner-message-list/banner-message-list-setting.actions';
import {DeleteBannerMessage} from '../../actions/banner-message/banner-message.actions';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
@Component({
  selector: 'banner-message-list',
  templateUrl: './banner-message-list.component.html',
  styleUrls: ['./banner-message-list.component.scss']
})

export class BannerMessageListComponent implements OnInit {

  bannerMessages$: Observable<BannerMessageModel[]>;
  bannerMessageListSetting$: Observable<BannerMessageListSetting>;
  pageNumber$: Observable<number>;

  pageNumber: number;
  pageSize: number;

  messageSort: BannerMessageSort;
  messageType: BannerMessageType;
  messageActive: string;
  messageFilter: BannerFilter;
  popoverTitle = 'Delete Banner Message ?';
  popoverMessage = 'Are you sure you want to delete the banner message?';
  confirmClicked = false;
  cancelClicked = false;
  constructor(
    private store: Store<state.State>
  ) {}

  ngOnInit() {
    this.store.dispatch(new GetBannerMessages({} as PagingAndSorting));
    this.bannerMessages$ = this.store.select(getBannerMessage);
    this.bannerMessageListSetting$ = this.store.select(getBMListStickySettingsSelector);
    this.bannerMessageListSetting$.subscribe( listSetting => {
      // The setting is coming back as readonly after ngrx-store upgrade
      // Create copy so that these values can be modified
      const bmSetting: BannerMessageListSetting = JSON.parse(JSON.stringify(listSetting));
      this.messageType = bmSetting.bannerMessageType;
      this.messageFilter = bmSetting.bannerFilter;
      this.messageSort = bmSetting.bannerMessageSort;
      this.messageActive = bmSetting.bannerMessageActive;
      this.pageNumber = bmSetting.bannerMessagePaging.pageNumber;
      this.pageSize = bmSetting.bannerMessagePaging.pageSize;
    });
  }

  getFilterText(messageFilter: any) {
    if (messageFilter.title || messageFilter.url || messageFilter.content) {
      let s = '';
      s = (messageFilter.title) ? '1' : '0';
      s += (messageFilter.url) ? '1' : '0';
      s += (messageFilter.content) ? '1' : '0';

      switch (s) {
        case '100':
          return 'Search on Title';
         case '010':
          return 'Search on Url';
        case '001':
          return 'Search on Content';
        default:
          return 'Filter';
      }
    } else {
      return 'Select a field to search';
    }
  }
  deleteMessage(isConfirmedDelete:boolean,bannerMessageModel:BannerMessageModel)
  {
    if(isConfirmedDelete)
    {

      this.store.dispatch(new DeleteBannerMessage(bannerMessageModel));

    }

  }
  updateListSetting() {
    this.store.dispatch(new SetBannerMessageListSetting(
      {
        bannerMessageActive: this.messageActive,
        bannerMessagePaging: {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        },
        bannerMessageType: {
          alert: this.messageType.alert,
          notice: this.messageType.notice,
          warning: this.messageType.warning
        },
        bannerFilter: {
          url: this.messageFilter.url,
          content: this.messageFilter.content,
          title: this.messageFilter.title,
          filterText: this.messageFilter.filterText
        },
        bannerMessageSort: {
          field: this.messageSort.field,
          direction: this.messageSort.direction
        }
      } as BannerMessageListSetting)
    );
  }

  sortBy(field, direction?) {
    if (direction === null || direction === undefined) {
      if (this.messageSort.field === field) {
        this.messageSort.direction = this.messageSort.direction === 'asc' ? 'desc' : 'asc';
      }
    } else {
      this.messageSort.direction = direction;
    }
    this.messageSort.field = field;
  }

  checkDate(value: any) {
    return isValidDate(value);
  }
}
