import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'banner-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'mcl-banner-admin';
  constructor(private router: Router) {}
  ngOnInit(): void {
    const deepLink = sessionStorage.getItem('deepLink');
    if (deepLink) {
      sessionStorage.removeItem('deepLink');
      this.router.navigate([deepLink]);
    }
  }
}
