<div class="jumbotron m-4">
  <h2>Banner Library</h2>
  <p>This project is a small javascript library that checks to see if a page 
     has a banner message defined, and does DOM manipulation to add the banner message to the page.</p>
  <h3>Installation</h3>
  <h6>Register the Mayo Clinic npm repository:</h6>
  <div class="card npm">npm config set registry https://artifactrepo.mayo.edu/repository/alm_public_read_npm/</div>
  <br />
  <h6>Install Banner Library package:</h6>
  <div class="card npm">npm install lpea-mcl-banner</div>
  <br/>
  <h3>Usage</h3>
  <p>You need to add a html element to your page that will hold the banner notifications.  The element must have an id of "banner-container".  Additionally you need to add a script tag that imports the banner javascript
  library.  Your banner container element must appear before your script import on the page, because the banner javascript file contains an Immediately Invoked Function Expression (IFFE) that calls out and checks if a banner notification is defined for the current
  page.</p>

  <h6>Example:</h6>
  <div class="card">
    <pre>
      <code>
      &lt;!DOCTYPE html&gt;
      &lt;html lang=&quot;en&quot;&gt;
        &lt;head&gt;
          &lt;meta charset=&quot;UTF-8&quot; /&gt;
          &lt;title&gt;Demo&lt;/title&gt;
        &lt;/head&gt;
        &lt;body&gt;
          &lt;p&gt;Demo App for testing purposes&lt;/p&gt;
          &lt;div style=&quot;display: flex; width: 100%; &quot;&gt;
            <mark>&lt;div id=&quot;banner-container&quot;&gt;Banner Goes Here&lt;/div&gt;</mark>
          &lt;/div&gt;
        <mark>&lt;script src=&quot;../dist/banner-min.js&quot;&gt;&lt;/script&gt;</mark>
        &lt;/body&gt;
      &lt;/html&gt;
      </code>
    </pre>
  </div>

</div>
