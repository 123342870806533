import {UntypedFormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';


export class TimeValidators {

  static twentyFourHour: ValidatorFn = (control: UntypedFormControl): ValidationErrors | null => {
    let value = control.value;
    if (isNaN(value)) {
      return {pattern: true};
    }
    value = parseInt(value, 10);
    if (value < 0 || value > 23) {
      return {bounds: true};
    }
    return null;
  }

  static sixtyMinute: ValidatorFn = (control: UntypedFormControl): ValidationErrors | null => {
    let value = control.value;
    if (isNaN(value)) {
      return {pattern: true};
    }
    value = parseInt(value, 10);
    if (value < 0 || value > 59) {
      return {bounds: true};
    }
    return null;
  }

}
