<ng-container *ngIf="(bannerMessages$ | async) as bannerMessages">
  <div class="d-block d-md-none p-3">
    <h2>Banner Messages</h2>
    <div class="form-inline mb-2">
      <div class="form-group mb-0 mr-2">
        <div class="input-group">
          <button id="btn-create-mobile" type="button" class="btn btn-primary" routerLink="create">
            Create
          </button>
        </div>
      </div>
    </div>

    <div class="form-inline mb-0">
      <div class="form-group mb-0 mr-3">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="bm-title-check-mobile" [(ngModel)]="messageFilter.title">
          <label for="bm-title-check-mobile" class="custom-control-label">Title</label>
        </div>
      </div>
      <div class="form-group mb-0 mr-3">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="bm-urls-check-mobile" [(ngModel)]="messageFilter.url">
          <label for="bm-urls-check-mobile" class="custom-control-label">URLs</label>
        </div>
      </div>

      <div class="form-group mb-0">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="bm-content-check-mobile" [(ngModel)]="messageFilter.content">
          <label for="bm-content-check-mobile" class="custom-control-label">Content</label>
        </div>
    </div>




    </div>





    <div class="form-inline mb-2 d-flex flex-nowrap">
      <div class="input-group input-wrapper">
        <svg class="icon input-svg">
          <use xlink:href="#search"></use>
        </svg>
        <input type="text"
                id="messages-filter-mobile"
                class="form-control"
                [(ngModel)]="messageFilter.filterText"
                (change)="updateListSetting($event)"
                [placeholder]="getFilterText(messageFilter)">

      </div>
    </div>

    <div class="form-inline mb-0">
      <div class="form-group mb-0">
        <label class="mb-0">Show Types</label>
      </div>
    </div>

    <div class="form-inline mb-2">
      <div class="form-group mb-0 mr-3">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="bm-alert-check-mobile" [(ngModel)]="messageType.alert">
          <label for="bm-alert-check-mobile" class="custom-control-label">Alert</label>
        </div>
      </div>
      <div class="form-group mb-0 mr-3">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="bm-notice-check-mobile" [(ngModel)]="messageType.notice">
          <label for="bm-notice-check-mobile" class="custom-control-label">Notice</label>
        </div>
      </div>
      <div class="form-group mb-0">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="bm-warning-check-mobile" [(ngModel)]="messageType.warning">
          <label for="bm-warning-check-mobile" class="custom-control-label">Warning</label>
        </div>
      </div>
    </div>

    <div class="form-inline mb-2 d-flex flex-nowrap">
      <div class="form-group mb-0 flex-grow-1 flex-shrink-0">
        <div class="input-group">
          <div class="input-group-prepend">
            <label for="bm-active-select-mobile" class="input-group-text">Active</label>
          </div>
          <select class="browser-default custom-select form-control"
                  id="bm-active-select-mobile"
                  [(ngModel)]="messageActive"
                  (change)="updateListSetting($event)">
            <option value ="All" selected class="bm-active-select-1">All</option>
            <option value="Active" class="bm-active-select-2">Active</option>
            <option value="Inactive" class="bm-active-select-3">Inactive</option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-inline mb-2 d-flex flex-nowrap justify-content-between">
      <div class="form-group mb-0 flex-shrink-0 flex-grow-0">
        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text" for="sort-by-mobile">Sort by</label>
          </div>
          <select class="custom-select" id="sort-by-mobile" (change)="sortBy($event.target.value)">
            <option value="title" [selected]="(messageSort.field === 'title') ? 'selected' : null">Title</option>
            <option value="fromDateUtc" [selected]="(messageSort.field === 'fromDateUtc') ? 'selected' : null">Start Date</option>
            <option value="toDateUtc" [selected]="(messageSort.field === 'toDateUtc') ? 'selected' : null">End Date</option>
            <option value="active" [selected]="(messageSort.field === 'active') ? 'selected' : null">Active</option>
            <option value="type" [selected]="(messageSort.field === 'type') ? 'selected' : null">Type</option>
          </select>
        </div>
      </div>
      <div class="form-group mb-0 flex-shrink-0 flex-grow-0">
        <div class="btn-group" role="group">
          <button type="button" class="btn" (click)="sortBy(messageSort.field, 'asc')"
                  [ngClass]="{'btn-primary': messageSort.direction === 'asc',
                              'btn-outline-primary': messageSort.direction === 'desc'}">
            Asc
          </button>
          <button type="button" class="btn" (click)="sortBy(messageSort.field, 'desc')"
                  [ngClass]="{'btn-primary': messageSort.direction === 'desc',
                              'btn-outline-primary': messageSort.direction === 'asc'}">
          Desc
          </button>
        </div>
      </div>
    </div>

    <div *ngFor="let bm of bannerMessages
              | bannerMessageFilterPipe: messageFilter
              | bannerMessageActivePipe: messageActive
              | bannerMessageTypePipe: messageType
              | orderBy: messageSort.field : messageSort.direction === 'desc': true
              | paginate: {itemsPerPage: pageSize, currentPage: pageNumber}; let i = index"
         class="banner-message card mb-3"
         id="banner-message-{{i}}-mobile">
      <div class="card-body">
        <h5 class="card-title mb-1 mr-4" id="message-title-{{i}}">{{bm.title}}</h5>
        <svg class="icon size-2"
             [ngClass]="{'fill-green': bm.active, 'fill-red': !bm.active}"
             id="message-active-{{i}}"
             style="position: absolute;top:1.25rem;right:1.25rem;"
             [attr.data-active]="bm.active">
          <use [attr.xlink:href]="bm.active ? '#check' : '#close'"></use>
        </svg>
        <p class="mb-1 small">
          <span class="message-start-datetime-{{i}}"><ng-container *ngIf="checkDate(bm.fromDateUtc)"> {{bm.fromDateUtc | date:'d MMM, yyyy HH:mm'}}</ng-container></span>
          <ng-container *ngIf="checkDate(bm.toDateUtc)">
            to <span class="message-end-datetime-{{i}}">{{bm.toDateUtc | date:'d MMM, yyyy HH:mm'}}</span>
          </ng-container>
        </p>
        <p class="mb-1"
           [ngClass]="{'text-warning': bm.type === 'Warning',
                     'text-info': bm.type === 'Notice',
                     'text-danger': bm.type === 'Alert'}">
          <svg class="icon" [ngSwitch]="bm.type">
            <use *ngSwitchCase="'Warning'" xlink:href="#exclamation-triangle"></use>
            <use *ngSwitchCase="'Notice'" xlink:href="#exclamation-circle"></use>
            <use *ngSwitchCase="'Alert'" xlink:href="#info-circle"></use>
          </svg>
          <span id="message-type-{{i}}-mobile"> {{bm.type}}</span>
        </p>
        <p class="mb-0">
          <a id="edit-message-btn-{{i}}-mobile" class="card-link" [routerLink]="['/messages/update', bm.bannerMessageId]">
            <svg class="icon"><use xlink:href="#pencil"></use></svg>
            Edit
          </a>
        </p>
      </div>
    </div>
    <div class="pagination">
      <pagination-controls (pageChange)="pageNumber = $event"
                           responsive="true"
                           previousLabel="Previous"
                           nextLabel="Next"></pagination-controls>
    </div>
  </div>
  <!-- Desktop Template for Banner Message List -->
  <div class="d-none d-md-block p-4">
    <h2>Banner Messages</h2>
    <div class="form-inline my-2">

      <button id="btn-create" type="button" class="btn btn-primary mr-4" routerLink="create">
        Create
      </button>

      <div class="form-group mr-2">
        <div class="input-group flex-nowrap input-wrapper">
          <svg class="icon input-svg">
            <use xlink:href="#search"></use>
          </svg>
          <input id="messages-filter" class="form-control" type="text"
                [(ngModel)]="messageFilter.filterText" (change)="updateListSetting($event)"
                [placeholder]="getFilterText(messageFilter)">
              </div>


      </div>

      <div class="input-group mr-2">
          <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="bm-title-check" [(ngModel)]="messageFilter.title" (change)="updateListSetting($event)">
              <label class="custom-control-label" for="bm-title-check">Title</label>
          </div>
      </div>
      <div class="input-group mr-2">
         <div class="custom-control custom-checkbox">

              <input type="checkbox" class="custom-control-input" id="bm-urls-check" [(ngModel)]="messageFilter.url" (change)="updateListSetting($event)">
              <label class="custom-control-label" for="bm-urls-check">URLs</label>
          </div>
      </div>

      <div class="input-group mr-2">
        <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="bm-content-check" [(ngModel)]="messageFilter.content" (change)="updateListSetting($event)">
            <label class="custom-control-label" for="bm-content-check">Content</label>
        </div>
    </div>



      <div class="d-flex align-content-center ml-auto">
        <div class="form-group mr-3">
          <label for="bm-active-select" class="mr-2">Filter Message on </label>
          <select id="bm-active-select" class="form-control"
                  [(ngModel)]="messageActive"
                  (change)="updateListSetting($event)">
            <option value="All" selected>All</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>

        <div class="form-group">
          <label class="mr-2">Show types:</label>
          <div class="custom-control custom-checkbox mr-2">
            <input type="checkbox" class="custom-control-input" id="bm-alert-check" [(ngModel)]="messageType.alert" (change)="updateListSetting($event)">
            <label class="custom-control-label" for="bm-alert-check">Alert</label>
          </div>

          <div class="custom-control custom-checkbox mr-2">
            <input type="checkbox" class="custom-control-input" id="bm-notice-check" [(ngModel)]="messageType.notice" (change)="updateListSetting($event)">
            <label class="custom-control-label" for="bm-notice-check">Notice</label>
          </div>

          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="bm-warning-check" [(ngModel)]="messageType.warning" (change)="updateListSetting($event)">
            <label class="custom-control-label" for="bm-warning-check">Warning</label>
          </div>
        </div>
      </div>
    </div>

    <div class="table-container">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col" (click)= "sortBy('title')" (change)="updateListSetting($event)">Title
              <svg class="icon icon-sort-down" *ngIf="messageSort.field === 'title' && messageSort.direction === 'desc'"><use xlink:href="#sort-down"></use></svg>
              <svg class="icon icon-sort-up" *ngIf="messageSort.field === 'title' && messageSort.direction === 'asc'"><use xlink:href="#sort-up"></use></svg>
            </th>
            <th scope="col" (click)= "sortBy('fromDateUtc')" (change)="updateListSetting($event)">Start Date Time
              <svg class="icon icon-sort-down" *ngIf="messageSort.field === 'fromDateUtc' && messageSort.direction === 'desc'"><use xlink:href="#sort-down"></use></svg>
              <svg class="icon icon-sort-up" *ngIf="messageSort.field === 'fromDateUtc' && messageSort.direction === 'asc'"><use xlink:href="#sort-up"></use></svg></th>
            <th scope="col" (click)= "sortBy('toDateUtc')">End Date Time
              <svg class="icon icon-sort-down" *ngIf="messageSort.field === 'toDateUtc' && messageSort.direction === 'desc'"><use xlink:href="#sort-down"></use></svg>
              <svg class="icon icon-sort-up" *ngIf="messageSort.field === 'toDateUtc' && messageSort.direction === 'asc'"><use xlink:href="#sort-up"></use></svg></th>
            <th scope="col">URLs</th>
            <th scope="col" (click)= "sortBy('active')" (change)="updateListSetting($event)">Active
              <svg class="icon icon-sort-down" *ngIf="messageSort.field === 'active' && messageSort.direction === 'desc'"><use xlink:href="#sort-down"></use></svg>
              <svg class="icon icon-sort-up" *ngIf="messageSort.field === 'active' && messageSort.direction === 'asc'"><use xlink:href="#sort-up"></use></svg></th>
            <th scope="col" (click)= "sortBy('type')" (change)="updateListSetting($event)">Type
              <svg class="icon icon-sort-down" *ngIf="messageSort.field === 'type' && messageSort.direction === 'desc'"><use xlink:href="#sort-down"></use></svg>
              <svg class="icon icon-sort-up" *ngIf="messageSort.field === 'type' && messageSort.direction === 'asc'"><use xlink:href="#sort-up"></use></svg></th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
        <tr *ngFor="let bm of bannerMessages
              | bannerMessageFilterPipe: messageFilter
              | bannerMessageActivePipe: messageActive
              | bannerMessageTypePipe: messageType
              | orderBy: messageSort.field : messageSort.direction === 'desc' : true
              | paginate: {itemsPerPage: pageSize, currentPage: pageNumber}; let i = index" id="banner-message-{{i}}">
          <td class="w-10" id="banner-message-title-{{i}}">{{bm.title}}</td>
          <td class="w-15" id="banner-message-FDate-{{i}}"><ng-container *ngIf="checkDate(bm.fromDateUtc)">{{bm.fromDateUtc | date: 'd MMM, yyyy HH:mm'}}</ng-container></td>
          <td class="w-15" id="banner-message-ToDate-{{i}}"><ng-container *ngIf="checkDate(bm.toDateUtc)">{{bm.toDateUtc | date: 'd MMM, yyyy HH:mm'}}</ng-container></td>
          <td rowspan="bm.url.length" class="w-25">
            <div *ngFor="let url of bm.urls" id="banner-message-url-{{i}}">
              {{url}}
            </div>
          </td>
           <td class="w-10">
            <svg class="icon-active" *ngIf="bm.active" id="banner-message-active-{{i}}"><use xlink:href="#check"></use></svg>
            <svg class="icon-inactive" *ngIf="!bm.active" id="banner-message-inActive-{{i}}"><use xlink:href="#close"></use></svg>
          </td>
          <td class="w-10"
          [ngClass]="{'text-warning': bm.type === 'Warning',
          'text-info': bm.type === 'Notice',
          'text-danger': bm.type === 'Alert'}">
            <svg class="icon" [ngSwitch]="bm.type">
              <use *ngSwitchCase="'Warning'" xlink:href="#exclamation-triangle"></use>
              <use *ngSwitchCase="'Notice'" xlink:href="#exclamation-circle"></use>
              <use *ngSwitchCase="'Alert'" xlink:href="#info-circle"></use>
            </svg>
            <span id="message-type-{{i}}"> {{bm.type}}</span>
          </td>
          <td class="w-10">
            <a id="edit-message-btn-{{i}}" class="card-link" [routerLink]="['/messages/update', bm.bannerMessageId]">
              <svg class="icon"><use xlink:href="#pencil"></use></svg>
              Edit
            </a>
            <a id="edit-message-btn-{{i}}"
            mwlConfirmationPopover
            [popoverTitle]="popoverTitle"
            [popoverMessage]="popoverMessage"
            placement="left"
            (confirm)="deleteMessage(true,bm)"
            (cancel)="deleteMessage(false,bm)"
            class="card-link" >
              <svg class="icon"><use xlink:href="#trash"></use></svg>
              Delete
            </a>

          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination">
      <pagination-controls (pageChange)="pageNumber = $event"
                           (pageChange)="updateListSetting($event)"
                           responsive="true"
                           previousLabel="Previous"
                           nextLabel="Next"></pagination-controls>
    </div>
  </div>
</ng-container>
