import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigSettings } from '../models/config-settings';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  public settings: ConfigSettings;
  constructor(private http: HttpClient) { }

  public async loadConfig(): Promise<void> {
    if (!this.settings) {
      try {
        this.settings = await this.http.get<ConfigSettings>('conf.json').toPromise();
      } catch (error) {
        return Promise.reject(`Could not load file 'conf.json': ${JSON.stringify(error)}`);
      }
      
    }
  }

  getSetting() {
    return this.settings;
  }

}
