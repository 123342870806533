<nav class="navbar navbar-expand-lg navbar-dark bg-dark">

  <svg class="mc-labs-logo d-none d-sm-block"><use xlink:href="#mc-labs_white"></use></svg>

  <a class="navbar-brand pl-2" routerLink="/">Show.IT</a>
  <button class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse navbar-dark"
       id="navbarSupportedContent">
    <ul class="nav navbar-nav">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/messages">Banner Messages</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/documentation">Documentation</a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto navbar-text pr-2 py-0" *ngIf="userProfile$ | async as _user">
      <li id="logged-in-user" class="navbar-userContainer">
          <img [attr.src]="sanitize(_user.photo)" class="navbar-user-photo" alt="user photo"/>
          <div class="nav-bar-username align-middle">
              {{_user.profile.displayName}}
              <br>
              {{_user.profile.jobTitle}}
          </div>
      </li>
    </ul>
  </div>
</nav>
