import { Injectable } from '@angular/core';
import { ConfigService} from './config.service';
import { initMany, TokenInfo } from 'mayo-js-oidc';

@Injectable({
  providedIn: 'root'
})

export class OauthService {

  public tokenInfos: TokenInfo[];

  constructor(private configService: ConfigService) { }

  public getTokens(): Promise<void> {
    console.log(`🦄 🍔 config settings:`, this.configService.settings);

    return initMany(this.configService.settings.oauthConfig)
        .then(tokens => {
          console.log('I can haz tokens', tokens);
          this.tokenInfos = tokens;
        }).catch(err => console.log('Failed to get tokens with error: ' + err));
  }
}
