import { Pipe, PipeTransform } from '@angular/core';
import { BannerMessageModel } from 'src/app/models/config-settings';

@Pipe({
    name: 'bannerMessageActivePipe'
})
export class BannerMessageActivePipe implements PipeTransform {
    transform(bms: BannerMessageModel[], messageActive: string): any[] {
        if (!bms) {
            return [];
        }
        if (!messageActive) {
            return bms;
        }
        switch (messageActive) {
            case 'Active':
              return bms.filter( b => b.active === true);
            case 'Inactive':
              return bms.filter( b => b.active === false);
            case 'All':
              return bms;
        }
    }
}
