import { BannerMessageListSetting } from '../../models/config-settings';
import {
    ConfigBannerMessageListSettingsActionTypes,
    ConfigBannerMessageListSettingsActions
} from '../../actions/banner-message-list/banner-message-list-setting.actions';

export const featureName = 'BannerMessageListSettings';

export interface BannerMessageListState {
    bmListState: BannerMessageListSetting;
}

export const initialState: BannerMessageListState = {
    bmListState: {
        bannerMessageType: {
            alert: true,
            notice: true,
            warning: true
        },
        bannerFilter: {
            title: true,
            content: true,
            url: true,
            filterText: ''
        },
        bannerMessageSort: {
            field: 'fromDateUtc',
            direction: 'desc'
        },
        bannerMessagePaging: {
            pageSize: 10,
            pageNumber: 0
        },
        bannerMessageActive: 'All'
    }
};

export function reducer(
    state = initialState,
    action: ConfigBannerMessageListSettingsActions,
  ): BannerMessageListState {
    if (action.type === ConfigBannerMessageListSettingsActionTypes.ConfigBannerMessageList) {
      return { ...state, bmListState: action.payload };
    } else {
      return state;
    }
}
