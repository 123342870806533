import { ValidatorFn, UntypedFormGroup, ValidationErrors, UntypedFormArray } from '@angular/forms';

export const excludeUrlValidator: ValidatorFn = (group: UntypedFormGroup): ValidationErrors | null => {
    const urls = group.controls.urls as UntypedFormArray;
    const excludeUrls = group.controls.excludeUrls as UntypedFormArray;
    // tslint:disable-next-line:no-shadowed-variable
    const hasAsterisk = urls.controls.filter((group: UntypedFormGroup) =>
        group.controls.url.value.indexOf('*') >= 0).length > 0;
    if (!hasAsterisk && excludeUrls.controls.length > 0) {
        return { ExcludeUrlError: 'Without asterisk "*" in Urls, not able add exclude urls' };
    }
    return null;
};
