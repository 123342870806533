import {Pipe, PipeTransform} from '@angular/core';
import {BannerMessageModel, BannerMessageSort} from '../../models/config-settings';

@Pipe({
  name: 'bannerMessageSortPipe',
  pure: false
})
export class BannerMessageSortPipe implements PipeTransform {

  transform(messages: BannerMessageModel[], options: BannerMessageSort): BannerMessageModel[] {
    const dir: number = (options.direction === 'asc') ? 1 : -1;
    const field = options.field;
    return messages.sort((messageA: BannerMessageModel, messageB: BannerMessageModel) => {
      if (typeof messageA[field] === 'boolean') {
        return messageA[field] === messageB[field] ? 0 : messageA[field] ?  dir : -1 * dir;
      } else if (messageA[field] instanceof Date) {
        return messageA[field] === messageB[field] ? 0 : messageA[field] < messageB[field] ? -1 * dir : dir;
      } else {
        return (messageA[field] || '').toString().toLowerCase().localeCompare((messageB[field] || '').toString().toLowerCase()) * dir;
      }
    });
  }
}
