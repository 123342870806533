<script src="banner-message-form.component.ts"></script>
<div class="p-2 m-2">
  <h1>{{id ? 'Update': 'Create'}} Banner Message</h1>

  <form [formGroup]="form">
    <div class="form-group">
      <label for="title" class="label">Title</label>
      <input type="title" class="form-control" id="title" formControlName="title" placeholder="Enter Title" trim="blur">
      <div *ngIf="isSubmitted && !form.get('title').valid" class="text-danger">
        <span *ngIf="form.get('title').hasError('required')" id="title-required">Please provide a title.</span>
        <span *ngIf="form.get('title').hasError('maxlength')" id="title-maxlength">Maximum length for title is 256 characters.</span>
      </div>
    </div>

    <div class="form-group">
      <label for="type" class="label">Type</label>

      <select class="form-control"
              id="type"
              formControlName="type">
        <option id="option-notice" value="Notice">Notice</option>
        <option id="option-alert" value="Alert">Alert</option>
        <option id="option-warning" value="Warning">Warning</option>
      </select>
      <div *ngIf="isSubmitted && !form.get('type').valid" class="text-danger">
        <span *ngIf="form.get('type').hasError('required')" id="type-required">Please select a type.</span>
      </div>
    </div>


    <div class="form-group">
      <label for="content" class="label">Content</label>
      <textarea class="form-control"
                formControlName="content"
                id="content"
                rows="5"
                trim="blur">
      </textarea>

      <div *ngIf="isSubmitted && !form.get('content').valid" class="text-danger">
        <span *ngIf="form.get('content').hasError('required')" id="content-required">Please provide message content.</span>
      </div>
    </div>

    <div class="form-group row">
    <div class="mb-col-2">
      <div class="label">Active</div>
      <div class="custom-control custom-switch my-auto">
        <input type="checkbox"
               class="custom-control-input"
               name="active"
               id="active"
               formControlName="active">
        <label class="custom-control-label" for="active"></label>
      </div>
    </div>
    <div class="mb-col-2 canBeHidden">
      <div class="label">Can Be Hidden</div>
      <div class="custom-control custom-switch my-auto">
        <input type="checkbox"
             class="custom-control-input"
             name="canBeHidden"
             id="canBeHidden"
             formControlName="canBeHidden">
        <label class="custom-control-label" for="canBeHidden"></label>
      </div>
    </div>
  </div>

    <div class="form-group dateTimeContainer">
      <div class="form-row">
        <div class="col">
          <div class="input-group">
            <label for="startDate"  class="label">Start Date</label>
            <input
              formControlName="startDate"
              type="text"
              id="startDate"
              class="form-control date"
              ngbDatepicker
              firstDayOfWeek="7"
              placeholder="YYYY-MM-DD"
              (ngModelChange)="checkEndDates()"
              [ngClass]="{'ng-invalid': form.errors?.startDateTimeInPast}"
              #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                <svg class="icon"><use xlink:href="#calendar-range"></use></svg>
              </button>
            </div>
          </div>
          <div *ngIf="isSubmitted && !form.get('startDate').valid" class="text-danger">
            <span *ngIf="form.controls.startDate.errors?.required" id="startDate-required">Please provide start date.</span>
            <span *ngIf="form.controls.startDate.errors?.ngbDate" id="startDate-invalid">Date must be in YYYY-MM-DD format.</span>
          </div>
        </div>
        <div class="col">
          <label for="startTimeHour" class="label">Start Time</label>
          <div class="form-inline">
            <input type="text" maxlength="2" placeholder="HH"
                   class="form-control time" id="startTimeHour" formControlName="startTimeHour"
                   (ngModelChange)="checkEndDates()" trim="blur"
                   (blur)="handleHourMinuteBlur($event)"
                   >
            <span class="p-1">:</span>
            <input type="text" maxlength="2" placeholder="MM"
                   class="form-control time" id="startTimeMinute" formControlName="startTimeMinute"
                   (ngModelChange)="checkEndDates()" trim="blur"
                   (blur)="handleHourMinuteBlur($event)"
                   >
          </div>
          <div *ngIf="isSubmitted && (!form.get('startTimeHour').valid || !form.get('startTimeMinute').valid)" class="text-danger">
            <span *ngIf="form.controls.startTimeHour.errors || form.controls.startTimeMinute.errors" id="startTime-Invalid">Please provide a valid start time.</span>
          </div>
        </div>
      </div>

      <div *ngIf="isSubmitted && !form.valid && form.errors?.startDateTimeInPast" class="text-danger">
        <span *ngIf="form.errors?.startDateTimeInPast" id="startDateTime-InPast">{{form.errors.startDateTimeInPast}}</span>
      </div>

    </div>

    <div class="form-group dateTimeContainer">
      <div class="form-row">
        <div class="col">
          <div class="input-group">
            <label for="endDate" class="label">End Date</label>
            <input
              formControlName="endDate"
              type="text"
              id="endDate"
              class="form-control date"
              placeholder="YYYY-MM-DD"
              ngbDatepicker
              firstDayOfWeek="7"
              [ngClass]="{'ng-invalid': form.errors?.endDateTimeRequired || form.errors?.InvalidDateTimeRange}"
              #d2="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" [disabled]="!isStartDateTimeValid()" type="button">
                <svg class="icon"><use xlink:href="#calendar-range"></use></svg>
              </button>
            </div>
          </div>

        </div>
        <div class="col">
          <label for="endTimeHour" class="label">End Time</label>
          <div class="form-inline">
            <input type="text" maxlength="2" placeholder="HH" trim="blur"
                   class="form-control time" id="endTimeHour" formControlName="endTimeHour"
                   (blur)="handleHourMinuteBlur($event)"
                   >
            <span class="p-1">:</span>
            <input type="text" maxlength="2" placeholder="MM" trim="blur"
                   class="form-control time" id="endTimeMinute" formControlName="endTimeMinute"
                   (blur)="handleHourMinuteBlur($event)"
                   >
          </div>
        </div>
      </div>

      <div *ngIf="isSubmitted && !form.valid" class="text-danger">
        <span *ngIf="form.errors?.endDateTimeRequired && !form.controls.endDate.errors?.ngbDate" id="endDateTime-required">{{form.errors.endDateTimeRequired}}</span>
        <span *ngIf="form.errors?.endTimeRequired" id="endTime-required">{{form.errors.endTimeRequired}}</span>
        <span *ngIf="form.controls.endDate.errors?.ngbDate" id="endDate-invalid">Date must be in YYYY-MM-DD format.</span>
        <span *ngIf="form.errors?.InvalidDateTimeRange" id="Invalid-range">{{form.errors.InvalidDateTimeRange}}</span>
      </div>

    </div>

    <div class="detail-container form-group">
      <label class="label">Include URLs</label>
      <banner-message-detail
        *ngFor="let d of getFormControl('urls').controls; let i = index;"
        (remove)="removeUrl(i)"
        [urlsArrayName] = "'urls'"
        [form]="form"
        [isSubmitted]="isSubmitted"
        [allowRemoval]="shouldAllowDetailRemoval(i)"
        [index]="i"></banner-message-detail>
    </div>

    <div class="form-group detail-btn-container">
      <button type="button" class="btn btn-outline-primary" id="add-btn" (click)="addUrl()">
          <svg class="icon form-add-svg"><use xlink:href="#plus"></use></svg>Add</button>
    </div>

    <div class="detail-container form-group" >
      <label class="label">Exclude URLs</label><br />
      <label class="notice">
        When using a wildcard to display a message on multiple pages, you can add<br>URLs for pages on which you don't want to dispaly the message<br>
      </label>
      <label class="label custom-error custom-lbl-err" *ngIf="form.errors && form.errors.ExcludeUrlError">{{ form.errors.ExcludeUrlError }}</label><br/>
      <banner-message-detail
        *ngFor="let d of getFormControl('excludeUrls').controls; let i = index;"
        (remove)="removeExcludeUrl(i)"
        [urlsArrayName] = "'excludeUrls'"
        [form]="form"
        [isSubmitted]="isSubmitted"
        [allowRemoval]="true"
        [index]="i"></banner-message-detail>
    </div>

    <div class="form-group detail-btn-container">
      <button type="button" class="btn btn-outline-primary" id="add-ex-btn" (click)="addExcludeUrl()"
        [disabled]="disableAddForExcludeUrl">
          <svg class="icon form-add-svg"><use xlink:href="#plus"></use></svg>Add</button>
    </div>

    <p class="mb-0" *ngIf="created.by && created.on">
      Created by {{created.by}} on {{created.on | date: 'd MMM, yyyy HH:mm'}}
    </p>
    <p class="mb-0" *ngIf="modified.by && modified.on">
      Last modified by {{modified.by}} on {{modified.on | date: 'd MMM, yyyy HH:mm'}}
    </p>

    <hr class="form-separator"/>
    <button *ngIf="!id" (click)="onCreate()" type="submit" class="btn btn-primary" id="create-btn">Create</button>
    <button *ngIf="id" (click)="onUpdate()" type="submit" class="btn btn-primary" id="update-btn" [disabled]="isUpdated === false">Update</button>
    <button (click)="onCancel()" type="button" class="btn btn-outline-secondary" id="cancel-btn">Cancel</button>
  </form>

</div>
